import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Implementing DELETE operation";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "implementing-delete-operation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#implementing-delete-operation",
        "aria-label": "implementing delete operation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementing DELETE operation`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#what-you-need"
          }}>{`What you need`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#1-map-http-method"
          }}>{`1. Map HTTP method`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#2-handle-parameters"
          }}>{`2. Handle parameters`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#3-implement-service-code"
          }}>{`3. Implement service code`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#delete-a-blog-post"
            }}>{`Delete a blog post`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#handle-exceptions"
            }}>{`Handle exceptions`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#add-blocking"
            }}>{`Add blocking`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#4-return-response"
          }}>{`4. Return response`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#5-test-an-error-case"
          }}>{`5. Test an error case`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#next-step"
          }}>{`Next step`}</a></p>
      </li>
    </ul>
    <p>{`In this step, we'll write a method for deleting a blog post. By completing this step, you'll learn to map your service with the HTTP DELETE (`}<a parentName="p" {...{
        "href": "type://@Delete:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Delete.html"
      }}>{`type://@Delete`}</a>{`) method, customize an exception handler, and use a blocking task executor.`}</p>
    <TutorialSteps current={7} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`You need to have the following files obtained from previous steps.
You can always `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/blob/main/tutorials/rest-api-annotated-service/src/main/java/example/armeria/server/blog/"
      }}>{`download`}</a>{` the full version, instead of creating one yourself.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Main.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogPost.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogService.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogServiceTest.java`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "1-map-http-method",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-map-http-method",
        "aria-label": "1 map http method permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Map HTTP method`}</h2>
    <p>{`Let's start `}<a parentName="p" {...{
        "href": "/docs/server-annotated-service#mapping-http-service-methods"
      }}>{`mapping the HTTP DELETE method`}</a>{` with our service method:`}</p>
    <ol>
      <li parentName="ol">{`Declare a service method, `}<inlineCode parentName="li">{`deleteBlogPost()`}</inlineCode>{` in the class `}<inlineCode parentName="li">{`BlogService`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Map this service method with the HTTP DELETE method by adding the `}<a parentName="li" {...{
          "href": "type://@Delete:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Delete.html"
        }}>{`type://@Delete`}</a>{` annotation.`}</li>
      <li parentName="ol">{`Bind the endpoint `}<inlineCode parentName="li">{`/blogs`}</inlineCode>{` to the method.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogService.java highlight=6",
        "filename": "BlogService.java",
        "highlight": "6"
      }}>{`import com.linecorp.armeria.server.annotation.Delete;

public final class BlogService {
  ...

  @Delete("/blogs")
  public void deleteBlogPost(int id) {
    // Delete a blog post
  }
}
`}</code></pre>
    <h2 {...{
      "id": "2-handle-parameters",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-handle-parameters",
        "aria-label": "2 handle parameters permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Handle parameters`}</h2>
    <p>{`Let's take the blog post ID (`}<inlineCode parentName="p">{`id`}</inlineCode>{`) as a path parameter for identifying the post to delete.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Take in the ID value as a path parameter by adding `}<inlineCode parentName="p">{`/blogs/:id`}</inlineCode>{` to the `}<a parentName="p" {...{
            "href": "type://@Delete:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Delete.html"
          }}>{`type://@Delete`}</a>{` annotation.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/docs/server-annotated-service#parameter-injection"
          }}>{`Inject the path parameter`}</a>{` to the service method by annotating the parameter with `}<a parentName="p" {...{
            "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
          }}>{`type://@Param`}</a>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogService.java highlight=6-7",
            "filename": "BlogService.java",
            "highlight": "6-7"
          }}>{`import com.linecorp.armeria.server.annotation.Param;

public final class BlogService {
  ...

  @Delete("/blogs/:id")
  public void deleteBlogPost(@Param int id) {
    // Delete a blog post
  }
}
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "3-implement-service-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-implement-service-code",
        "aria-label": "3 implement service code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Implement service code`}</h2>
    <p>{`In this step, write the code to delete a blog post, handle an exception, and block the operation.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#delete-a-blog-post"
        }}>{`Delete a blog post`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#handle-exceptions"
        }}>{`Handle exceptions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#add-blocking"
        }}>{`Add blocking`}</a></li>
    </ul>
    <h3 {...{
      "id": "delete-a-blog-post",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#delete-a-blog-post",
        "aria-label": "delete a blog post permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Delete a blog post`}</h3>
    <p>{`Deleting a given blog post in this tutorial means removing a blog post from the map, `}<inlineCode parentName="p">{`blogPosts`}</inlineCode>{`. However, in real services you would be performing this action on a database.`}</p>
    <p>{`To delete a blog post, copy line 3 into the `}<inlineCode parentName="p">{`deleteBlogPost()`}</inlineCode>{` method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogService.Java highlight=3 showlineno=true",
        "filename": "BlogService.Java",
        "highlight": "3",
        "showlineno": "true"
      }}>{`@Delete("/blogs/:id")
public void deleteBlogPost(@Param int id) {
  BlogPost removed = blogPosts.remove(id);
}
`}</code></pre>
    <h3 {...{
      "id": "handle-exceptions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#handle-exceptions",
        "aria-label": "handle exceptions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Handle exceptions`}</h3>
    <p>{`What if there is no such post to delete? We can check if the blog exists before attempting to remove the blog post. Here, let's handle it after the attempt.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Throw an `}<inlineCode parentName="p">{`IllegalArgumentException`}</inlineCode>{` if no blog post exists with a given ID.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogService.java",
            "filename": "BlogService.java"
          }}>{`@Delete("/blogs/:id")
public void deleteBlogPost(@Param int id) {
  ...

  if (removed == null) {
    throw new IllegalArgumentException("The blog post does not exist. ID: " + id);
  }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create an exception handler for the blog service:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`Create a file, `}<inlineCode parentName="li">{`BadRequestExceptionHandler.java`}</inlineCode>{`.`}</li>
          <li parentName="ol">{`In the file, declare a custom exception handler implementing Armeria's `}<a parentName="li" {...{
              "href": "type://ExceptionHandlerFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ExceptionHandlerFunction.html"
            }}>{`type://ExceptionHandlerFunction`}</a>{` interface.`}</li>
        </ol>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BadRequestExceptionHandler.java",
            "filename": "BadRequestExceptionHandler.java"
          }}>{`package example.armeria.server.blog;

import com.linecorp.armeria.server.annotation.ExceptionHandlerFunction;
import com.fasterxml.jackson.databind.ObjectMapper;

public class BadRequestExceptionHandler implements ExceptionHandlerFunction {
  private static final ObjectMapper mapper = new ObjectMapper();
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Implement your own exception handler by overriding the default `}<inlineCode parentName="p">{`handleException()`}</inlineCode>{` method. Add a code block for handling the `}<inlineCode parentName="p">{`IllegalArgumentException`}</inlineCode>{` thrown. For this tutorial, return a BAD REQUEST as the response.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BadRequesExceptionHandler.java highlight=11,15,17",
            "filename": "BadRequesExceptionHandler.java",
            "highlight": "11,15,17"
          }}>{`import com.fasterxml.jackson.databind.node.ObjectNode;
import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.common.HttpStatus;
import com.linecorp.armeria.server.ServiceRequestContext;

public class BadRequestExceptionHandler implements ExceptionHandlerFunction {
  ...

  @Override
  public HttpResponse handleException(ServiceRequestContext ctx, HttpRequest req, Throwable cause) {
    if (cause instanceof IllegalArgumentException) {
        String message = cause.getMessage();
        ObjectNode objectNode = mapper.createObjectNode();
        objectNode.put("error", message);
        return HttpResponse.ofJson(HttpStatus.BAD_REQUEST, objectNode);
    }
    return ExceptionHandlerFunction.fallthrough();
  }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Assign the exception handler to the `}<inlineCode parentName="p">{`deleteBlogPost()`}</inlineCode>{` method by annotating the `}<inlineCode parentName="p">{`deletePost()`}</inlineCode>{` method with the `}<a parentName="p" {...{
            "href": "type://@ExceptionHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ExceptionHandler.html"
          }}>{`type://@ExceptionHandler`}</a>{` as follows.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogService.java highlight=2",
            "filename": "BlogService.java",
            "highlight": "2"
          }}>{`import com.linecorp.armeria.server.annotation.ExceptionHandler;

@Delete("/blogs/:id")
@ExceptionHandler(BadRequestExceptionHandler.class)
public void deleteBlogPost(@Param int id) { ... }
`}</code></pre>
      </li>
    </ol>
    <h3 {...{
      "id": "add-blocking",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-blocking",
        "aria-label": "add blocking permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add blocking`}</h3>
    <p>{`With real services, accessing and operating on a database takes time. We need to hand over such blocking tasks to `}<a parentName="p" {...{
        "href": "/docs/server-annotated-service#specifying-a-blocking-task-executor"
      }}>{`blocking task executor`}</a>{` so that the EventLoop isn't blocked. There are a few options to implement this; we'll annotate our service method with the `}<a parentName="p" {...{
        "href": "type://@Blocking:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Blocking.html"
      }}>{`type://@Blocking`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogService.java highlight=6",
        "filename": "BlogService.java",
        "highlight": "6"
      }}>{`import com.linecorp.armeria.server.annotation.Blocking;

public final class BlogService {
  ...

  @Blocking
  @Delete("/blogs/:id")
  @ExceptionHandler(BadRequestExceptionHandler.class)
  public void deleteBlogPost(@Param int id) { ... }
}
`}</code></pre>
    <h2 {...{
      "id": "4-return-response",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-return-response",
        "aria-label": "4 return response permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Return response`}</h2>
    <p>{`We've already handled returning the not found error in the `}<a parentName="p" {...{
        "href": "#handle-exceptions"
      }}>{`exception handling section`}</a>{`. Here, we'll return a response for successful deletion.`}</p>
    <ol>
      <li parentName="ol">{`Replace the return type of the `}<inlineCode parentName="li">{`deleteBlogPost()`}</inlineCode>{` method from `}<inlineCode parentName="li">{`void`}</inlineCode>{` to `}<a parentName="li" {...{
          "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
        }}>{`type://HttpResponse`}</a>{`.`}</li>
      <li parentName="ol">{`Return a response using Armeria's `}<a parentName="li" {...{
          "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
        }}>{`type://HttpResponse`}</a>{`, containing `}<a parentName="li" {...{
          "href": "type://HttpStatus#NO_CONTENT:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpStatus.html#NO_CONTENT"
        }}>{`type://HttpStatus#NO_CONTENT`}</a>{`.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogService.java highlight=9,11",
        "filename": "BlogService.java",
        "highlight": "9,11"
      }}>{`import com.linecorp.armeria.common.HttpResponse;

public final class BlogService {
  ...

  @Blocking
  @Delete("/blogs/:id")
  @ExceptionHandler(BadRequestExceptionHandler.class)
  public HttpResponse deleteBlogPost(@Param int id) {
    ...
    return HttpResponse.of(HttpStatus.NO_CONTENT);
  }
}
`}</code></pre>
    <h2 {...{
      "id": "5-test-an-error-case",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#5-test-an-error-case",
        "aria-label": "5 test an error case permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Test an error case`}</h2>
    <p>{`Add a test method as follows to test if our exception handler is working properly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`import static org.assertj.core.api.Assertions.assertThat;

@Test
@Order(5)
void badRequestExceptionHandlerWhenTryingDeleteMissingBlogPost() throws JsonProcessingException {
    final WebClient client = WebClient.of(server.httpUri());
    final AggregatedHttpResponse res = client.delete("/blogs/100").aggregate().join();
    assertThat(res.status()).isSameAs(HttpStatus.BAD_REQUEST);
    assertThatJson(res.contentUtf8()).isEqualTo("{\\"error\\":\\"The blog post does not exist. ID: 100\\"}");
}
`}</code></pre>
    <p>{`Run all the test cases on your IDE or using Gradle.
Check that you see the test is passed.`}</p>
    <p>{`You can test this also with Armeria's `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation service`}</a>{`. See `}<a parentName="p" {...{
        "href": "/tutorials/rest/blog/add-services-to-server#using-docservice-after-adding-service-methods"
      }}>{`Using DocService after adding service methods`}</a>{` for instructions.`}</p>
    <h2 {...{
      "id": "next-step",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#next-step",
        "aria-label": "next step permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next step`}</h2>
    <p>{`In this step, we've written a method for a DELETE operation and used Armeria's annotations; `}<a parentName="p" {...{
        "href": "type://@Delete:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Delete.html"
      }}>{`type://@Delete`}</a>{`, `}<a parentName="p" {...{
        "href": "type://@Param:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Param.html"
      }}>{`type://@Param`}</a>{`, `}<a parentName="p" {...{
        "href": "type://@ExceptionHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ExceptionHandler.html"
      }}>{`type://@ExceptionHandler`}</a>{` and `}<a parentName="p" {...{
        "href": "type://@Blocking:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Blocking.html"
      }}>{`type://@Blocking`}</a>{`.`}</p>
    <p>{`We've come to the end of this tutorial. Next, try adding more service methods to the tutorial or have a go at developing a service of your own.`}</p>
    <TutorialSteps current={7} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      